import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fadeIn } from "../../../Theme/Functions/GlobalAnimations";
import logo from "../../../Assets/img/AlphaSolar/logo/brand-logo.webp";
import teckatLogo from "../../../Assets/img/AlphaSolar/logo/logo.png";

import { m } from "framer-motion";

const FooterAlpha = () => {
  return (
    <div>
      {/* Section Start */}
      <section className="py-[80px] overflow-hidden md:py-[40px]">
        <Container>
          <Row className="md:justify-center">
            <Col lg={7} sm={10} className="md:mt-12 md:mb-12 md:text-center">
              <m.h2
                className="heading-3 font-semibold text-darkgray font-serif mb-0"
                {...{ ...fadeIn, transition: { delay: 0.2 } }}
                style={{ color: "#004d9a" }}
              >
                Reach out to us via email for assistance & inquiries!
              </m.h2>
            </Col>
            <m.div
              className="col-lg-5 pl-24 lg:pl-[15px] md:mb-12 md:text-center"
              {...{ ...fadeIn, transition: { delay: 0.4 } }}
            >
              <span className="mt-[7px] text-darkgray text-xmd font-serif block">
                Get in touch with our team
              </span>
              <h3 className="heading-6 text-[#27AE60] font-semibold font-serif mb-0 mt-[5px]">
                <a
                  aria-label="mail"
                  href="mailto:sales@alphasolar.co.in"
                  className="text-decoration-line-bottom-thick hover:opacity-50 hover:text-[#828282] text-lg"
                  style={{ color: "#388c07" }}
                >  
                  sales@alphasolar.co.in
                </a>
              </h3>
              <h3 className="heading-6 text-[#27AE60] font-semibold font-serif mb-0 mt-[5px]">
                <a
                  aria-label="mail"
                  href="tel:+919237385097"
                  className="text-decoration-line-bottom-thick hover:opacity-50 hover:text-[#828282] text-lg"
                  style={{ color: "#388c07" }}
                >
                  +91 9237385097
                </a>
              </h3>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Footer Start */}

      <Container className="py-[50px] border-t border-mediumgray">
        <Row className="md:justify-between items-center">
          <Col className="col-12 col-xl-5 col-md-6 order-2 order-xl-1 sm:mb-[15px] xs:flex xs:justify-center xs:text-center">
            <ul className="flex justify-start  sm:justify-center text-left text-sm font-serif xs:flex-col xs:text-center">
              <li className="mr-[35px] md:mr-[15px] xs:mb-[7px]">
                <Link
                  className="text-[#828282] hover:opacity-70 hover:text-[#828282] uppercase"
                  to="/about-us"
                >
                  About us
                </Link>
              </li>
              <li className="mr-[35px] md:mr-[15px] xs:mb-[7px]">
                <Link
                  className="text-[#828282] hover:opacity-70 hover:text-[#828282] uppercase"
                  to="/projects"
                >
                  Projects
                </Link>
              </li>
              <li className="mr-[35px] md:mr-[15px] xs:mb-[7px]">
                <Link
                  className="text-[#828282] hover:opacity-70 hover:text-[#828282] uppercase"
                  to="/contact"
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </Col>
          <Col
            xl={{ span: 2, order: 2 }}
            xs={{ span: 12, order: 1 }}
            className="text-center lg:mb-[30px]"
          >
            <Link to="/">
              <img
                src={logo}
                className="my-0 mx-auto"
                alt="logo"
                width="111"
                height="36"
              />
            </Link>
          </Col>
          <Col
            xl={5}
            md={6}
            xs={{ span: 12, order: 3 }}
            className="text-center"
          >
            <p className="mb-0 uppercase text-sm  sm:text-center">
              © 2023 alphasolar.co.in | All Rights Reserved.
            </p>
            <div className="d-flex text-center gap-2 justify-content-center leading-none mt-3 d-flex align-items-center justify-center">
              <p>Developed by : </p>
              <img src={teckatLogo} alt="" width={"23px"} height={"auto"} />
              <a
                rel="noreferrer"
                href="https://www.teckat.com/"
                className=" font-medium hover:opacity-50 text-[#388C40] hover:text-[#388c07]"
                target="_blank"
              >
                Teckat.com
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Footer End */}
    </div>
  );
};

export default FooterAlpha;
